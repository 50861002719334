"use client";

import { useRouter } from "next/navigation";
import NotFoundIllus from "shared-assets/images/404.webp";
import { ErrorPage } from "shared-ui";
import { getAccountUserInfo } from "shared-utils/authentication";

export default function NotFound() {
  const router = useRouter();
  const userInfo = getAccountUserInfo();

  const handleOnClick = () => {
    if (userInfo?.user?.id) {
      router.replace("/");
      return;
    }
    router.back();
  };

  return (
    <ErrorPage
      imageUrl={NotFoundIllus.src}
      onClickPrimaryAction={handleOnClick}
      title="Maaf, Halaman Tidak Ditemukan."
      desc={
        <div>
          Kami tidak dapat menemukan halaman yang sesuai dengan permintaan Anda.
          <br /> Ini mungkin disebabkan oleh salah ketik URL, perubahan pada
          halaman, atau halaman yang telah dihapus.
        </div>
      }
    />
  );
}
